import React from "react"
import PropTypes from "prop-types"
import { LinkedinShareButton } from "react-share"
import In from "../assets/images/credits/in-credits.svg"
import "../styles/social-share.scss"

const socialShareCredits = ({ socialConfig, tags, direction, page }) => (
  <>
    <div className="post-social-credits">
      <LinkedinShareButton
        url={socialConfig.config.url}
        className="button is-outlined is-rounded linkedin"
        title={socialConfig.config.title}
      >
        <div className="share">
          <p>
            {" "}
            share <img src={In} alt="LinkedIn" />{" "}
          </p>
        </div>
      </LinkedinShareButton>
    </div>
  </>
)

socialShareCredits.propTypes = {
  socialConfig: PropTypes.shape({
    twitterHandle: PropTypes.string.isRequired,
    config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
}
socialShareCredits.defaultProps = {
  tags: [],
}

export default socialShareCredits
