import React from "react"
import { StaticQuery, graphql } from "gatsby"

const creditsImages = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query credits {
            SuperOps {
              peopleList(where: { position: "Credits" }) {
                id
                name
                picture {
                  url
                }
                order
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.peopleList.map((people, i) => {
              const { name, picture, order } = people
              return (
                <>
                  <div
                    data-sal="fade"
                    style={{ "--sal-delay": 1.8 + i * 0.1 + "s" }}
                    data-sal-easing="ease-in"
                  >
                    <img
                      src={picture.url}
                      className={`hero-credits-images position-absolute credits-images-${order}`}
                      id={`creditsImg${order}`}
                      alt={name}
                      width="10"
                      height="10"
                    />
                  </div>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default creditsImages
