import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Badge from "../../assets/images/credits/badge.svg"
import Icon from "../../assets/images/credits/frame-icon.png"
import ShareCredits from "../../components/social_share_credits"

const fame = props => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query fame {
            SuperOps {
              peopleList(orderBy: order_ASC, where: { position: "Fame" }) {
                id
                name
                title
                picture {
                  url
                }
                background
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.peopleList.map((people, i) => {
              const { name, title, picture, background } = people
              const twitterHandle = data.site.siteMetadata.twitterHandle
              const url = data.site.siteMetadata.url
              const shareTags = []
              const shareSlug = "hall-of-fame"
              return (
                <div className="column-3 column-m-2 column-s-1 mt40">
                  <div
                    data-sal="slide-up"
                    style={{ "--sal-delay": 0.2 + "s" }}
                    data-sal-easing="ease-out-bounce"
                    data-sal-duration="500"
                    className="h100"
                  >
                    <div className="fame-wrap prelative">
                      <img src={Icon} className="top-left" alt="img" />
                      <img src={Icon} className="top-right" alt="img" />
                      <img src={Icon} className="bottom-left" alt="img" />
                      <img src={Icon} className="bottom-right" alt="img" />

                      <div className="picture prelative">
                        <div
                          className="frame"
                          style={{ background: background }}
                        >
                          <img src={picture.url} className="pic" alt="img" />
                          <img src={Badge} className="badger" alt="img" />
                        </div>
                      </div>
                      <p className="name">{name} </p>
                      <p className="title">{title} </p>

                      <ShareCredits
                        socialConfig={{
                          twitterHandle,
                          config: {
                            url: `${url}${shareSlug}`,
                            title: props.seo.title,
                          },
                        }}
                        tags={shareTags}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default fame
