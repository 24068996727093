import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Button, Col, Container, Row } from "react-bootstrap"
import parse from 'html-react-parser'
import { Link } from "react-scroll"
import Lines from "../../assets/images/credits/credits-lines.png"
import Logo from "../../assets/images/credits/credits-logo.svg"
import HOF from "../../assets/images/credits/HOF.svg"
import LastLeft from "../../assets/images/credits/last-left.svg"
import LastRight from "../../assets/images/credits/last-right.svg"
import LetterLeft from "../../assets/images/credits/letter-left.svg"
import LetterRight from "../../assets/images/credits/letter-right.svg"
import CreditsImages from "../../components/credits/credits-images"
import Fame from "../../components/credits/fame"
import Slide from "../../components/utility/slide_content"
import Frame from "../../components/common/frame"

function HallOfFame() {
  var d = new Date()
  var currentYear = d.getFullYear()
  return (
    <>
      <StaticQuery
        query={graphql`
          query contentCredits {
            SuperOps {
              pages(where: { title: "Credits" }) {
                title
                pageBlock {
                  id
                  content {
                    html
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.pages.map(page => {
              const { pageBlock, seo } = page
              return (
                <>
                  <Frame
                    seo={seo}
                    bodyClassName="credits hof"
                  >
                    <section className="credits-main">
                      {/* <ReactPageScroller > */}
                      <div className="hero-wrap">
                        <div className="hero prelative">
                          <center>
                            <img src={Lines} className="lines" alt="img" />
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <a href="/">
                                <img src={Logo} className="logo" alt="img" />
                              </a>
                            </Slide>
                            <Slide style={{ "--sal-delay": "0.5s" }}>
                              {parse(pageBlock[0].content.html)}
                            </Slide>

                            <Slide style={{ "--sal-delay": "0.8s" }}>
                              <span className="info">
                                {" "}
                                {parse(
                                  pageBlock[1].content.html
                                )}{" "}
                              </span>
                            </Slide>
                            <Slide style={{ "--sal-delay": "1.1s" }}>
                              <Link
                                className="affordance"
                                to={"letter"}
                                spy={true}
                                smooth={true}
                                duration={500}
                              >
                                {" "}
                                {parse(
                                  pageBlock[2].content.html
                                )}{" "}
                              </Link>
                            </Slide>

                            <CreditsImages />
                          </center>
                        </div>
                      </div>
                      <div className="others-wrap">
                        <div id="letter" className="letter prelative">
                          <img
                            src={LetterLeft}
                            className="letter-left"
                            alt="img"
                          />
                          <img
                            src={LetterRight}
                            className="letter-right"
                            alt="img"
                          />
                          <center>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <Container>
                                <Slide style={{ "--sal-delay": "0.5s" }}>
                                  <span className="heading">
                                    {parse(pageBlock[3].content.html)}
                                  </span>
                                </Slide>
                                <Slide style={{ "--sal-delay": "0.5s" }}>
                                  <span className="info">
                                    {parse(pageBlock[4].content.html)}
                                  </span>
                                </Slide>

                                <Slide style={{ "--sal-delay": "0.5s" }}>
                                  <img
                                    src="https://media.graphcms.com/fqv3ZIoHTdWCKvLTbo7J"
                                    className="ap-img"
                                    height="10"
                                    width="10"
                                    alt="img"
                                  />

                                  <div className="ap-sign">
                                    <img
                                      src="https://media.graphcms.com/Sh2wecIoSomd0phVjRlI"
                                      height="10"
                                      width="10"
                                      alt="img"
                                    />

                                    <span className="last">
                                      {parse(
                                        pageBlock[5].content.html
                                      )}
                                    </span>
                                  </div>
                                </Slide>
                              </Container>
                            </Slide>
                          </center>
                        </div>

                        <div className="fame prelative">
                          <img src={LastLeft} className="last-left" alt="img" />
                          <img
                            src={LastRight}
                            className="last-right"
                            alt="img"
                          />
                          <center>
                            <img src={Lines} className="lines" alt="img" />
                            <Container>
                              <div className="column-container">
                                <div className="column-3 column-m-2 column-s-1 mt40">
                                  <div
                                    data-sal="slide-up"
                                    style={{ "--sal-delay": "0.2s" }}
                                    data-sal-easing="ease-out-bounce"
                                    data-sal-duration="500"
                                    className="h100"
                                  >
                                    <center>
                                      <img
                                        src={HOF}
                                        className="hof-image"
                                        alt="img"
                                      />
                                    </center>
                                  </div>
                                </div>

                                <Fame seo={seo} />
                              </div>
                            </Container>
                          </center>
                        </div>

                        <div className="cta">
                          <center>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <Container>
                                <Row>
                                  <Col lg={8}>
                                    {parse(pageBlock[6].content.html)}
                                  </Col>
                                  <Col lg={4}>
                                    <a href="/signup">
                                      <Button> SIGN ME UP </Button>
                                    </a>
                                  </Col>
                                </Row>
                              </Container>
                            </Slide>
                          </center>
                        </div>

                        <div className="copy">
                          <center>
                            <Slide style={{ "--sal-delay": "0.2s" }}>
                              <Container>
                                <p>
                                  {" "}
                                  © {currentYear} SuperOps Inc. All rights
                                  reserved{" "}
                                </p>
                              </Container>
                            </Slide>
                          </center>
                        </div>
                      </div>
                      {/* </ReactPageScroller> */}
                    </section>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default HallOfFame
